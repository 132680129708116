const SvgStar2 = (props) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#Star2_svg__a)">
      <path
        d="m6.447 12.56.847 2.296a.22.22 0 0 0 .412 0l.847-2.295a6.768 6.768 0 0 1 4.008-4.008l2.295-.847a.22.22 0 0 0 0-.412l-2.295-.847A6.768 6.768 0 0 1 8.553 2.44L7.706.144a.22.22 0 0 0-.412 0l-.847 2.295A6.768 6.768 0 0 1 2.44 6.447l-2.295.847a.22.22 0 0 0 0 .412l2.295.847a6.767 6.767 0 0 1 4.008 4.008Z"
        fill="#A66E49"
      />
    </g>
    <defs>
      <filter
        id="Star2_svg__a"
        x={-1}
        y={-1}
        width={17}
        height={17}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend in2="shape" result="effect1_innerShadow_466_110716" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={-1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.62932 0 0 0 0 0.484075 0 0 0 0 0.390703 0 0 0 0.6 0" />
        <feBlend
          in2="effect1_innerShadow_466_110716"
          result="effect2_innerShadow_466_110716"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgStar2;
