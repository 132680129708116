const SvgStopdetail = (props) => (
  <svg
    width="1.6rem"
    height="1.6rem"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.166 8A6.166 6.166 0 1 1 1.834 8a6.166 6.166 0 0 1 12.332 0Z"
      stroke="#141415"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.288 9.962-2.514-1.5V5.231"
      stroke="#141415"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgStopdetail;
