const SvgMaster = (props) => (
  <svg
    width="4rem"
    height="4rem"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#master_svg__a)">
      <path fill="#fff" d="M.5 6.955h40v26.091H.5z" opacity={0.01} />
      <path fill="#FF5F00" d="M15.225 11.5h10.552v16.997H15.225z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.313 20a10.737 10.737 0 0 1 4.186-8.497C16.005 8.021 9.552 8.528 5.675 12.67a10.7 10.7 0 0 0 0 14.665c3.877 4.141 10.33 4.649 14.824 1.166a10.737 10.737 0 0 1-4.186-8.5Z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.206 26.424v.706h-.074v-.706h-.157v-.147h.398v.146h-.167Zm.773-.147v.853h-.084v-.646l-.13.554h-.09l-.131-.554v.646h-.087v-.853h.12l.14.61.142-.61h.12ZM38.232 20c0 4.138-2.396 7.913-6.17 9.722a11.085 11.085 0 0 1-11.563-1.225 10.755 10.755 0 0 0 4.187-8.499c0-3.316-1.544-6.449-4.188-8.498a11.085 11.085 0 0 1 11.564-1.225c3.774 1.809 6.17 5.584 6.17 9.722V20Z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <clipPath id="master_svg__a">
        <path
          fill="#fff"
          transform="translate(.5 6.955)"
          d="M0 0h40v26.091H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMaster;
