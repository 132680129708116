const SvgWarning = (props) => (
  <svg
    width="1.6rem"
    height="1.6rem"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.966 2.345 1.16 12.455a1.205 1.205 0 0 0 .437 1.64c.182.105.388.16.598.16h11.61a1.19 1.19 0 0 0 1.035-.6 1.207 1.207 0 0 0 0-1.2L9.035 2.344A1.198 1.198 0 0 0 8 1.745a1.19 1.19 0 0 0-1.034.6Z"
      fill="#AB212B"
    />
    <path
      d="M8.075 5.464h-.151a.677.677 0 0 0-.675.679v3.244c0 .375.302.679.675.679h.151a.677.677 0 0 0 .676-.68V6.144a.677.677 0 0 0-.676-.68ZM8 12.638c.414 0 .75-.338.75-.755a.753.753 0 0 0-.75-.755.753.753 0 0 0-.751.755c0 .417.336.755.75.755Z"
      fill="#fff"
    />
  </svg>
);

export default SvgWarning;
