import valid from 'card-validator'

const NumberValidate = (number, cardNoLength) => {
  let cardNo = number.replace(/_| /g,'');
  let numberValidation = valid.number(cardNo, cardNoLength);

  return numberValidation.isValid;
}

const HolderValidate = (holder) => {
  let holderValidation = valid.cardholderName(holder);
  
  return holderValidation.isValid
}

const ExpirationValidate = (exp) => {
  let expirationNo = exp.replace(/\/|_/g,'');
  let expirationValidation = valid.expirationDate(expirationNo);

  return expirationValidation.isValid
}

const CVVValidate = (cvv, cvvLength) => {
  let cvvValidate = valid.cvv(cvv, cvvLength);

  return cvvValidate.isValid
}


export {
  NumberValidate,
  HolderValidate,
  ExpirationValidate,
  CVVValidate
}