import { useRouter } from 'next/router'
import { useEffect } from 'react'
import s from './index.module.sass'

// components
import Radio from '../UI/Radio'
import GlassButton from '../UI/Buttons/glass'

// icons
import Plus2Icon from '../Icons/icon/Plus2'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { FormatPrice } from '../../hooks/useGetPrice'

export default function Charge({ footer = true, stage, value, setValue, onProtect, b_allProtected, mobile = false, buttonState, onPay, tpGrandTotal, grandTotal, tip, trip, tripType, paymentTab = 0, displayTicketProtection, ...props }) {

  const dispatch = useDispatch();

  const ADDITIONAL_SERVICE_TOTAL = parseFloat(tpGrandTotal) + parseFloat(tip.confirm && tip.amount != 0 ? tip.amount : 0)
  
  return (
    <div className={s['body__charge']} {...props}>
      <h1 className={s['body__charge-title']}>Payment summary</h1>

      <div className={s['body__charge-info']}>
        <div className={s.line}>
          <span className={`${s['line-title']} ${s['line-title--bold']}`}>
            <b>
              {tripType == 0 && "One-Way Flight"}
              {tripType == 1 && "Round-Trip Flight"}
              {tripType == 2 && "Multi-City Flight"}
            </b>
          </span>
          <span className={`${s['line-value']} ${s['line-value--bold']}`}><b>{FormatPrice(trip.grandTotal)}</b></span>
        </div>
        <div className={s.line}>
          <span className={s['line-title']}>Adults x {trip?.adultInfo?.number}</span>
          <span className={s['line-value']}>{FormatPrice(trip?.adultInfo?.price)}</span>
        </div>
        {trip?.childInfo?.number > 0 &&
          <div className={s.line}>
            <span className={s['line-title']}>Children x {trip?.childInfo?.number}</span>
            <span className={s['line-value']}>{FormatPrice(trip?.childInfo?.price)}</span>
          </div>
        }
        {trip?.infInfo?.number > 0 &&
          <div className={s.line}>
            <span className={s['line-title']}>Infants x {trip?.infInfo?.number}</span>
            <span className={s['line-value']}>{FormatPrice(trip?.infInfo?.price)}</span>
          </div>
        }
      </div>
      <br /><br />
      <div className={s['body__charge-info']}>
        <div className={s.line}>
          <span className={`${s['line-title']} ${s['line-title--bold']}`}><b>Additional Service</b></span>
          <span className={`${s['line-value']} ${s['line-value--bold']}`}><b>{FormatPrice(ADDITIONAL_SERVICE_TOTAL)}</b></span>
        </div>
        {displayTicketProtection &&
          <div className={`${s.line} ${s['line--spacer']}`}>
          <span className={s['line-title']}>Ticket Protection</span>
          {b_allProtected
            ? <span className={s['line-value']}>{FormatPrice(tpGrandTotal)}</span>
            : <button onClick={onProtect} className={s['line-button']}> <Plus2Icon /> Add ticket protection</button>
          } 
          </div>
        }
        {tip.confirm &&
          <div className={s.line}>
            <span className={s['line-title']}>Tips for an awesome service</span>
            <span className={s['line-value']}>{FormatPrice(tip.amount)}</span>
          </div>
        }
      </div>
      <div className={s['body__charge-footer']}>
        <span className={s.title}>Total to be paid</span>
        <span className={s.value}>{FormatPrice(grandTotal)}</span>
      </div>

      <div className={s['body__charge-confirm']} style={(stage === 2 && mobile === false) ? { borderRadius: 0 } : null}>
        <div onClick={() => setValue(!value)} className={s.checkbox}><Radio checked={value} /></div>
        <div className={s.info}>
          <p>
            <b>By checking this box I Affirm</b> that I have read, understood and accepted present <a rel="noreferrer" target="_blank" href="https://fivestarairways.com/terms">Terms and Conditions</a>, <a rel="noreferrer" target="_blank" href="https://fivestarairways.com/privacy_policy">Privacy Policy</a> and <a rel="noreferrer" target="_blank" href="https://fivestarairways.com/terms">airline liability limitations</a>. Confirmed the accuracy of flight itinerary, and verified the spelling of the name(s) of the passenger(s) to be matching passenger(s) passport(s) / <a rel="noreferrer" target="_blank" href="https://fivestarairways.com/terms">valid travel document(s)</a>, as well as I have taken the consent of Credit Card Holder and all the passengers regarding the <a rel="noreferrer" href="https://fivestarairways.com/terms" target="_blank">Terms and Conditions</a> and <a rel="noreferrer" href="https://fivestarairways.com/privacy_policy" target="_blank">Privacy Policy</a>.
          </p>
        </div>
      </div>
      {(stage === 2 && mobile === false) &&
        <div className={s['pay']}><GlassButton onClick={onPay} disabled={buttonState()}> <img src="/lock.svg" alt="lock"  /> {paymentTab == 0 ?  'Pay Securely Now' :  'Submit Payment'}</GlassButton></div>
      }

      {footer &&
        <div className={s['body__copyright']}>

          <img src="/paymentFooter.svg" alt="logos"  />
          <div className={s['body__copyright-text']}>
            <b>© Five Star Airways.</b>
            <span>All rights reserved. CST # 2119950-40.</span>
          </div>

        </div>
      }

    </div>
  )
}