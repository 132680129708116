const SvgDepartureReturn = (props) => (
  <svg
    width="3.2rem"
    height="3.2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.08 25.939H1.969v2.959h28.11v-2.96ZM30.924 12.083a2.215 2.215 0 0 0-2.715-1.569l-7.864 2.109-10.209-9.52-2.855.768 6.125 10.616-7.353 1.968-2.908-2.286L1 14.746l2.693 4.668 1.132 1.96 2.374-.636 7.864-2.108 6.428-1.724 7.864-2.108a2.215 2.215 0 0 0 1.569-2.715Z"
      fill="#474749"
    />
  </svg>
);

export default SvgDepartureReturn;
