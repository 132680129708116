import s from './index.module.sass'

// components
import MoveLabelInput from '../UI/Input/moveLabel'
import GenderSelect from '../UI/GenderSelect'
import DropDown from '../UI/DropDown'

//hooks
import useCapitalize from '../../hooks/useCapitalize'

// redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setName,
  setMiddleName,
  setLastName,

  setBmonth,
  setBday,
  setByear,

  setGender,

  setFrequest
} from '../../store/passenger'

// jsons
import monthJson from '../../jsons/monthJson.json'

export default function PassengerItem({ id, info, mobile , validation, submitted, disabled = false}) {

  const dispatch = useDispatch()

  const GetCapitalize = (string) => useCapitalize(string);

  return (
    <>
      {mobile
        ?
        <div className={s['body__passenger-form']}>
          <MoveLabelInput disabled={disabled} label="First Name" value={info.firstName} setValue={(e) => dispatch(setName({ id, value: GetCapitalize(e)}))} maxLength="32" hasError={submitted &&validation.errors['firstName']} />
          <MoveLabelInput disabled={disabled} label="Middle Name (optional)" value={info.middleName} setValue={(e) => dispatch(setMiddleName({ id, value: GetCapitalize(e) }))} required={false} maxLength="32" />
          <MoveLabelInput disabled={disabled} label="Last Name" value={info.lastName} setValue={(e) => dispatch(setLastName({ id, value: GetCapitalize(e) }))} maxLength="32" hasError={submitted &&validation.errors['lastName']}/>
          <div className={s['body__passenger-form--three']}>
            <DropDown
              disabled={disabled}
              label="Month"
              move={true}
              smallText={true}
              value={monthJson.items.find(obj => obj.code == info.dob.month)?.text}
              setValue={(e) => dispatch(setBmonth({ id, value: e.code }))}
              items={monthJson.items}
              hasError={submitted &&validation.errors['month']}
            />
            <MoveLabelInput disabled={disabled} inputmode="tel" mask="99" label="Day" move={true} smallText={true} value={info.dob.day} setValue={(e) => dispatch(setBday({ id, value: e }))} hasError={submitted &&validation.errors['day']}/>
            <MoveLabelInput disabled={disabled} inputmode="tel" mask="9999" label="Year" move={true} smallText={true} value={info.dob.year} setValue={(e) => dispatch(setByear({ id, value: e }))} hasError={submitted &&validation.errors['year']}/>
          </div>
          <GenderSelect value={info.gender} setValue={(e) => {if(!disabled) dispatch(setGender({ id, value: e }))} } style={{ height: '100%' }} hasError={submitted &&validation.errors['gender']}/>
          {validation.errors['message'] &&
            <div className={s['body__passenger__err']} >{validation.errors['message']}</div>
          }
          <MoveLabelInput disabled={disabled} label="Passenger's Notes" value={info.frequest} setValue={(e) => dispatch(setFrequest({ id, value: e }))} maxLength={128} required={false}/>
        </div>
        :
        <div className={s['pcForm']}>
          <div className={s['pcForm__info']}>
            <MoveLabelInput disabled={disabled} label="First Name" value={info.firstName} setValue={(e) => dispatch(setName({ id, value: GetCapitalize(e) }))} maxLength="32" hasError={submitted &&validation.errors['firstName']}/>
            <MoveLabelInput disabled={disabled} label="Middle Name (optional)" value={info.middleName} setValue={(e) => dispatch(setMiddleName({ id, value: GetCapitalize(e) }))} required={false} maxLength="32" />
            <MoveLabelInput disabled={disabled} label="Last Name" value={info.lastName} setValue={(e) => dispatch(setLastName({ id, value: GetCapitalize(e) }))} maxLength="32" hasError={submitted &&validation.errors['lastName']} />
          </div>
          <div className={s['pcForm__bottom']}>

            <div className={s['pcForm__date']}>
              <DropDown
                disabled={disabled}
                label="Month"
                move={true}
                smallText={true}
                value={monthJson.items.find(obj => obj.code == info.dob.month)?.text}
                setValue={(e) => dispatch(setBmonth({ id, value: e.code }))}
                items={monthJson.items}
                hasError={submitted && validation.errors['month']}
              />
              <MoveLabelInput disabled={disabled} inputmode="tel" mask="99" label="Day" move={true} smallText={true} value={info.dob.day} setValue={(e) => dispatch(setBday({ id, value: e }))} hasError={submitted &&validation.errors['day']} />
              <MoveLabelInput disabled={disabled} inputmode="tel" mask="9999" label="Year" move={true} smallText={true} value={info.dob.year} setValue={(e) => dispatch(setByear({ id, value: e }))} hasError={submitted &&validation.errors['year']} />
            </div>
            <div className={s['pcForm__date-gender']}>
              <GenderSelect value={info.gender} setValue={(e) => {if(!disabled) dispatch(setGender({ id, value: e }))} } style={{ height: '100%' }} hasError={submitted &&validation.errors['gender']}/>
            </div>
          </div>
          {validation.errors['message'] &&
            <div className={s['pcForm__err']} >{validation.errors['message']}</div>
          }
          <MoveLabelInput disabled={disabled} label="Passenger's Notes" value={info.frequest} setValue={(e) => dispatch(setFrequest({ id, value: e }))} maxLength={128} required={false}/>
        </div>
      }
    </>
  )
}