import {useState} from 'react'
import s from './index.module.sass'

export default function Toggle({active, change}) {

  return (
    <div onClick={change} className={`${s.toggle} ${active ? s['toggle--active'] : null}`}>
      <div className={s['toggle-sphere']}></div>
    </div>
  )
}