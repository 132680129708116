const GetTicketProtectionPrice = (pax, trip) => {

  if(!pax || !trip || Object.keys(trip).length == 0) return null;

  const percentage = 10;
  let ticketPrice = null;

  switch(pax.ptc) {
    case "ADULT":
      ticketPrice = trip.adultInfo.price
      break;
    case "CHILD":
      ticketPrice = trip.childInfo.price
      break;    
    case "INFANT":
      ticketPrice = trip.infInfo.price
      break;
  }

  if (ticketPrice) {
    const price = (percentage / 100) * ticketPrice;
    return price < 150 ? 150 : price;
  } else {
    return null;
  }
}

const GetTicketProtectionPriceTotal = (paxes = [], ignorePaxProtection = false, trip) => {
  let total = 0;

  paxes.forEach((pax) => {
    if (pax.protect || ignorePaxProtection) total += GetTicketProtectionPrice(pax, trip);
  })

  return total;
}

const GetGrandTotal = (flightPrice, ticketProtectionPrice, tips) => {

  return parseFloat(flightPrice) + parseFloat(ticketProtectionPrice) + parseFloat(tips)
}

const FormatPrice = (price, currency) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });

  return formatter.format(price);
}

export {
  GetTicketProtectionPrice,
  GetTicketProtectionPriceTotal,
  GetGrandTotal,
  FormatPrice
}