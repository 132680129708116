import { useState, useEffect } from 'react'
import s from './index.module.sass'

// components
import PriceSelector from '../UI/PriceSelector'
import Toggle from '../UI/Toggle'

// icons
import MinusIcon from '../Icons/icon/Minus'
import PlusIcon from '../Icons/icon/Plus'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { increase, decrease, setConfirm, setTips } from '../../store/tips'
export default function Tips({disabled = false}) {

  const dispatch = useDispatch()
  const [priceValue, setPriceValue] = useState(0)
  const [marks, setMarks] = useState({
    0: <span>NO<br />$0</span>,
    20: <span>Good<br />$100</span>,
    40: <span>Great<br />$150</span>,
    60: <span>Excellent<br />$200</span>,
    80: <span>Perfect<br />$300</span>,
  })

  const TIPS_INFO = useSelector((state) => state.tips)

  useEffect(() => {
    if (TIPS_INFO.amount === 0) setPriceValue(0)
    else if (TIPS_INFO.amount === 100) setPriceValue(20)
    else if (TIPS_INFO.amount === 150) setPriceValue(40)
    else if (TIPS_INFO.amount === 200) setPriceValue(60)
    else if (TIPS_INFO.amount > 299) setPriceValue(80)

    if (TIPS_INFO.amount != 0) {
      dispatch(setConfirm(true));
    } else {
      dispatch(setConfirm(false));
    }

  }, [TIPS_INFO.amount])

  return (
    <div className={s['body__service']}>
      <div className={s['avatar']}>
        <div className={s['body__service-avatar']}>
          <div className={s['status']}></div>
          <img className={s['img']} src="/agent/agent.png" alt="agent"  />
        </div>
      </div>
      <h1 className={s['body__service-title']}>How was my service?</h1>
      <span className={s['body__service-description']}>Think i did better?</span>

      <div className={s['body__service-counter']}>
        <input inputMode='tel' disabled={disabled} className={`counter_staic ${s['body__service-counter-text']}`} value={`$${TIPS_INFO.amount}`} onChange={(e) => { if(!disabled) {dispatch(setTips(parseInt(e.target.value.replaceAll('$', '')) || 0))} } } />        {/* <button onClick={() => dispatch(increase())}> <PlusIcon /> </button> */}
      </div>

      <PriceSelector disabled={disabled} value={priceValue} marks={marks} />

      <div className={s['body__service-confirm']}>
        <div onClick={() => {if(!disabled) dispatch(setConfirm(!TIPS_INFO.confirm)) } }><Toggle active={TIPS_INFO.confirm} /></div>
        <p>I agree that this amount will be charged in addition to the cost of the airline ticket(s)</p>
      </div>
    </div>
  )
}