import { useState } from 'react'
import s from './index.module.sass'


// components
import MoveLabelInput from '../Input/moveLabel'

export default function Dropdown({ items=[], ...props }) {

  const [isOpen, setIsOpen] = useState(false)

  let itemClasses = [s['item']];
  if(props.hasError) itemClasses.push(s['item__list--error'])

  let classesList = [s['item__list']]
  if (isOpen) classesList.push(s['item__list--open'])
  if(props.relative) classesList.push(s['item__list--relative'])


  const handleOpen = () => {
    if (!props.disabled) setIsOpen(!isOpen)
  }

  return (

    <div onClick={handleOpen}>
      <div className={itemClasses.join(' ')}>

        <div className={s['item__input']}><MoveLabelInput disabled={true} {...props} /></div>
        <div className={classesList.join(' ')}>
          {items.map((item, index) => {
            return <span onClick={() => props.setValue(item)} key={index}>{item.text}</span>
          })}
        </div>

      </div>
      {isOpen && <div className={s.close} onClick={() => setIsOpen(false)}></div> }
    </div>

  )
}