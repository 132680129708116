import s from './index.module.sass'

// icons
import TipIcon from '../../Icons/Info'

export default function Tip({multiLine=false, children, ...props}) {
  let classes = [s.tip]
  if (multiLine) classes.push(s['tip--multi'])

  return (
    <div className={classes.join(' ')} {...props}>
      <div><TipIcon/></div>
      <span className={s['tip-text']}>{children}</span>
    </div>
  )
}