import s from './index.module.sass'

// icons
import Star2Icon from '../Icons/icon/Star2'

export default function LineTitle({children, style}) {
  let classes = [s.title]
  if (style) classes.push(s['title--'+style])
  return (
    <div className={classes.join(' ')}>
      <div className={s['title__content']}><span>{children}</span></div>
      <div className={s['title__icon']}><Star2Icon /></div>
    </div>
  )
}