import s from './index.module.sass'

export default function GenderSelect({ value, setValue, hasError,  ...props }) {
  return (
    <div className={s.selector} {...props}>

      <Item text="Male" value={value} onClick={setValue} hasError={hasError} />
      <Item text="Female" value={value} onClick={setValue} hasError={hasError} />
      
    </div>
  )
}

const Item = ({text, value, onClick, hasError}) => {

  let classes = [s['selector__item']]
  if (value === text) classes.push(s['selector__item--active'])
  if(hasError) classes.push(s['selector__item--error']);
  
  return (
    <div onClick={() => onClick(text)} className={classes.join(' ')}>
      <div className={s['selector__item-curcle']}></div>
      <label className={s['selector__item-label']}>{text}</label>
    </div>
  )
}