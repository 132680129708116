const SvgInfo = (props) => (
  <svg
    width="2.4rem"
    height="2.4rem"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12.001} fill="#B87D0A" r={9} />
    <path
      d="M11.995 16v-4M11.99 8.204H12"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgInfo;
