import { useState, useEffect } from 'react'
import s from './index.module.sass'
import InputMask from 'react-input-mask'
import valid, { number } from 'card-validator'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { setLengthNumber, setLengthCvv } from '../../../../store/billing'

export default function MoveLabelInput({ value = "", setValue = () => { }, label, move = true, smallText = false, fullInput = false, dark = false, cardValidation, inputmode = 'text', hasError = false, generateCardNumberMask, required = true, ...props }) {

  const dispatch = useDispatch()

  const CARD_INFO = useSelector((state) => state.billing.card)

  let labelClass = [s['block-label']]
  let inputClass = [s['block-input']]
  let blockClass = [s.block]

  if(!value) value = "";

  let numberValidation = null;
  if(cardValidation) {
    numberValidation = valid.number(value.substr(0, 4))
  }

  useEffect(() => {
    if (numberValidation && numberValidation.card) {
      if (CARD_INFO.lengths.cvv !== numberValidation.card.code.size) dispatch(setLengthCvv(numberValidation.card.code.size))
      if (CARD_INFO.lengths.number !== numberValidation?.card?.lengths[0]) dispatch(setLengthNumber(numberValidation?.card?.lengths[0]))
      if(generateCardNumberMask) generateCardNumberMask(numberValidation.card)
    }
  }, [numberValidation])

  if (move) {

    if (value.length > 0) labelClass.push(s['block-label--active'])
    if (value.length > 0) inputClass.push(s['block-input--active'])
  } else {
    labelClass.push(s['block-label--active'])
    inputClass.push(s['block-input--active'])
  }
  if (smallText) {
    labelClass.push(s['block-label--smallText'])
  }
  if (fullInput) inputClass.push(s['block-input--full'])

  if (dark) {
    blockClass.push(s['block--dark'])
    labelClass.push(s['block-label--dark'])
  }

  if (hasError) {
    blockClass.push(s['block--error'])
  }

  return (
    <div className={blockClass.join(' ')}>
      <label className={labelClass.join(' ')}>{label} {required && <sup style={{color: 'red'}}>*</sup>} </label>
      <InputMask
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={inputClass.join(' ')}
        inputmode={inputmode}
        {...props}
      />

      {(cardValidation && numberValidation.card?.type) && <img alt={numberValidation.card?.type} src={`/banks/${numberValidation.card?.type}.svg`} className={s.bank} />}
    </div>
  )
}