
const EmailValidate = (email) => {

  if (email.length < 10) return false

  let reg = String(email.replaceAll(" ", ''))
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  if (reg === null) return false
  else return true
}

const PhoneValidate = (phone) => {

  let reg = phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,24}$/im)

  if (reg === null) return false
  else return true

}

const NameValidate = (name) => {

  return name.length > 1 && name.length < 64

}


export {
  EmailValidate,
  PhoneValidate,
  NameValidate
}