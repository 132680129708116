const SvgPlus2 = (props) => (
  <svg
    width="1.6rem"
    height="1.6rem"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8c0-4.667 1.334-6 6-6 4.667 0 6 1.333 6 6s-1.385 6-6 6-6-1.333-6-6Z"
      fill="#fff"
    />
    <path
      d="M8 6v4M10 8H6"
      stroke="#A66E49"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgPlus2;
