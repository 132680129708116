import React, { useEffect, useState } from "react";

export default function useIsMobile() {
  if (typeof window !== "undefined") {
    const [width, setWidth] = useState(Math.min(window.screen.width, window.innerWidth) );
    const handleWindowResize = () => {
      setWidth(Math.min(window.screen.width, window.innerWidth));
    }

    useEffect(() => {
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      }
    }, []);

    return (width <= 768);
  }
}