import PropTypes from 'prop-types'
import s from './index.module.sass'

export default function GlassButton({type, size,children, textColor, ...props}) {

  let classes = [s.btn, s[`btn--${type}`]]
  if (size !== undefined) classes.push(s[`btn--${size}`])

  return (
    <button 
      className={classes.join(' ')}
      style={{color: textColor}}
      {...props}
    >
      {children}
    </button>
  )
}


GlassButton.propTypes = {
  type: PropTypes.string
}