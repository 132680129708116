import {useState} from 'react'
import s from './index.module.sass'

export default function LoadingSpinner({containerStyle, spinnerStyle, ...props}) {

  return (
    <div className={s['container']} style={containerStyle}>
      <div className={s['spinner']} style={spinnerStyle}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      {/* <div className={s['text']}>Please Wait </div> */}
    </div>
  )
}