const SvgCopy = (props) => (
  <svg
    width="2rem"
    height="2rem"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 3.75H7.5a1.877 1.877 0 0 0-1.875 1.875v11.25A1.877 1.877 0 0 0 7.5 18.75H15a1.877 1.877 0 0 0 1.875-1.875V5.625A1.877 1.877 0 0 0 15 3.75Zm.625 13.125A.625.625 0 0 1 15 17.5H7.5a.625.625 0 0 1-.625-.625V5.625A.626.626 0 0 1 7.5 5H15a.626.626 0 0 1 .625.625v11.25Z"
      fill="#A66E49"
    />
    <path
      d="M13.125 2.5a.625.625 0 1 0 0-1.25H6.25a3.129 3.129 0 0 0-3.125 3.125V15a.625.625 0 1 0 1.25 0V4.375A1.877 1.877 0 0 1 6.25 2.5h6.875Z"
      fill="#A66E49"
    />
  </svg>
);

export default SvgCopy;
