const SvgMaestro = (props) => (
  <svg
    width="4rem"
    height="4rem"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#maestro_svg__a)">
      <path fill="#7673C0" d="M15.991 11.819h9.014v16.366h-9.014z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.566 20A10.43 10.43 0 0 1 20.5 11.82a10.227 10.227 0 0 0-13.934 1.122c-3.645 3.987-3.645 10.135 0 14.122A10.227 10.227 0 0 0 20.5 28.185a10.43 10.43 0 0 1-3.935-8.184Z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.185 26.183v.664h-.072v-.664h-.134v-.138h.34v.138h-.134Zm.661-.138v.802l-.074.005v-.606l-.112.52h-.077l-.112-.52v.6h-.074v-.8h.106l.12.572.12-.573h.103ZM37.167 20c0 3.985-2.252 7.62-5.8 9.362a10.216 10.216 0 0 1-10.869-1.18A10.447 10.447 0 0 0 24.434 20c0-3.193-1.45-6.21-3.936-8.183a10.216 10.216 0 0 1 10.87-1.179c3.548 1.742 5.8 5.378 5.799 9.364Z"
        fill="#00A1DF"
      />
    </g>
    <defs>
      <clipPath id="maestro_svg__a">
        <path
          fill="#fff"
          transform="translate(3.833 9.59)"
          d="M0 0h33.333v20.82H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMaestro;
