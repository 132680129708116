const SvgVisa = (props) => (
  <svg
    width="4rem"
    height="4rem"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#visa_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0742A6"
    >
      <path d="M18.278 25.102h-2.7l1.689-10.17h2.7l-1.69 10.17ZM28.066 15.18a6.81 6.81 0 0 0-2.422-.433c-2.666 0-4.544 1.385-4.555 3.365-.023 1.46 1.344 2.272 2.366 2.759 1.045.498 1.4.823 1.4 1.266-.01.681-.844.995-1.622.995-1.078 0-1.655-.161-2.533-.54l-.356-.163-.378 2.283c.634.281 1.8.53 3.012.541 2.833 0 4.678-1.363 4.7-3.473.01-1.158-.711-2.045-2.267-2.77-.945-.465-1.523-.779-1.523-1.255.011-.433.49-.876 1.555-.876a4.694 4.694 0 0 1 2.012.39l.244.107.367-2.196ZM32.9 14.932h2.088l2.178 10.17h-2.5s-.244-1.169-.322-1.526h-3.467l-.566 1.526h-2.834l4.011-9.327c.278-.66.767-.843 1.412-.843Zm-.167 3.721s-.855 2.262-1.078 2.846H33.9a871.66 871.66 0 0 1-.623-2.943l-.188-.876c-.08.225-.195.535-.272.745a7.418 7.418 0 0 0-.084.228ZM13.322 14.932l-2.644 6.935-.29-1.407c-.488-1.623-2.021-3.386-3.732-4.263l2.422 8.894h2.855l4.244-10.16h-2.855Z" />
      <path d="M8.222 14.932H3.878l-.045.205c3.39.844 5.634 2.878 6.556 5.324l-.945-4.674c-.155-.65-.633-.834-1.222-.855Z" />
    </g>
    <defs>
      <clipPath id="visa_svg__a">
        <path
          fill="#fff"
          transform="translate(3.833 14.747)"
          d="M0 0h33.333v10.506H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVisa;
