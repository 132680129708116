import s from "./index.module.sass";
import moment from "moment";

// icons
import StopDetailIcon from "../Icons/Stopdetail";
import WarrningIcon from "../Icons/Warning";

//hooks
import useGetHoursMinutes from "../../hooks/useGetHoursMinutes";

export default function MailReis({ tripInfo, leg, mobile = false }) {
	const GetHoursMinutes = time => useGetHoursMinutes(time);

	return (
		<>
			{mobile && (
				<div className={s.reis}>
					<div className={s["reis__header"]}>
						<span className={s["reis__header-date"]}>
							<b>{moment(tripInfo.depDate).format("ddd, MMM D")}</b> ·{" "}
							{tripInfo.travelTime} ·{" "}
							{tripInfo.noOfStops == "0 Stop" ? "Non Stop" : tripInfo.noOfStops}
						</span>
					</div>

					{leg.flights.map((flight, index) => {
						return (
							<div key={index}>
								<InfoReis flight={flight} mobile={mobile} />
								{(flight.layover ||
									flight.separatedTickets ||
									flight.technicalStop) && (
									<div className={s["reis__time"]}>
										{flight.layover && (
											<div>
												<div>
													<span className={s["reis__time-text"]}>
													<StopDetailIcon />
														<b>
															{
																GetHoursMinutes(flight.layover.layoverTime)
																	.hours
															}{" "}
															hr{" "}
															{
																GetHoursMinutes(flight.layover.layoverTime)
																	.minutes
															}{" "}
															min <span>layover</span> ·{" "}
															{flight.layover.airportName} (
															{flight.layover.airport})
														</b>
													</span>
												</div>
												{flight.layover.airportChangePresent && (
													<div className={s["warrning"]}>
														<WarrningIcon /> Airport change from{" "}
														{flight.layover.airport} to{" "}
														{flight.layover.newAirport}
													</div>
												)}
											</div>
										)}
										{flight.separatedTickets && (
											<div className={s["warrning"]}>
												{" "}
												<WarrningIcon /> Booked as separate tickets
											</div>
										)}
										{flight.technicalStop && (
											<div className={s["warrning"]}>
												{" "}
												<WarrningIcon /> Technical Stop present in{" "}
												{flight.technicalStop.airportName}
												{" ("}
												{flight.technicalStop.airport}
												{")"}
											</div>
										)}
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}

			{!mobile && (
				<div className={s.reis}>
					<div className={s["reis__header"]}>
						<span className={s["reis__header-date"]}>
							<b>{moment(tripInfo.depDate).format("ddd, MMM D")}</b> ·{" "}
							{tripInfo.travelTime} ·{" "}
							{tripInfo.noOfStops == "0 Stop" ? "Non Stop" : tripInfo.noOfStops}
						</span>
					</div>

					{leg.flights.map((flight, index) => {
						return (
							<div key={index}>
								<InfoReis flight={flight} mobile={mobile} />

								{(flight.layover ||
									flight.separatedTickets ||
									flight.technicalStop) && (
									<div className={s["reis__time"]}>
										{flight.layover && (
											<div>
												<div>
													<span className={s["reis__time-text"]}>
													<StopDetailIcon />
														<b>
															{
																GetHoursMinutes(flight.layover.layoverTime)
																	.hours
															}{" "}
															hr{" "}
															{
																GetHoursMinutes(flight.layover.layoverTime)
																	.minutes
															}{" "}
															min <span>layover</span> ·{" "}
															{flight.layover.airportName} (
															{flight.layover.airport})
														</b>
													</span>
												</div>
												{flight.layover.airportChangePresent && (
													<div className={s["warrning"]}>
														<WarrningIcon /> Airport change from{" "}
														{flight.layover.airport} to{" "}
														{flight.layover.newAirport}
													</div>
												)}
											</div>
										)}
										{flight.separatedTickets && (
											<div className={s["warrning"]}>
												{" "}
												<WarrningIcon /> Booked as separate tickets
											</div>
										)}
										{flight.technicalStop && (
											<div className={s["warrning"]}>
												{" "}
												<WarrningIcon /> Technical Stop present in{" "}
												{flight.technicalStop.airportName}
												{" ("}
												{flight.technicalStop.airport}
												{")"}
											</div>
										)}
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}

const InfoReis = ({ flight, mobile }) => {
	const GetHoursMinutes = time => useGetHoursMinutes(time);

	return (
		<>
			{mobile && (
				<div className={s.info}>
					<div className={s["info-avatar"]}>
						<img
							className={s["img"]}
							src={
								"https://offer.otti.omeron.co/static/square/" +
								flight.airline +
								".png"
							}
							alt="carrier"
							
						/>
					</div>
					<div className={s["info__info"]}>
						<div className={s.infoBlock}>
							<b
								className={`${s["infoBlock-time"]} ${s["infoBlock-time--line"]}`}>
								{flight.depTime}
							</b>
							<span className={s["infoBlock-locate"]}>
								{flight.depAirportCity} ({flight.depAirport})
							</span>
							<div className={s["infoBlock__adv"]}>
								<span className={s["infoBlock__adv-text"]}>
									Travel time: {GetHoursMinutes(flight.travelTime).hours} hr{" "}
									{GetHoursMinutes(flight.travelTime).minutes} min
									{flight.redEye && (
										<>
											&nbsp; · &nbsp;
											<div className={s["warrning"]}>
											  <WarrningIcon />{" "} Overnight 
											</div>
										</>
									)}
								</span>
							</div>
						</div>

						<div className={s.infoBlock}>
							<b className={s["infoBlock-time"]}>
								{flight.arrTime}{" "}
								{flight.dayDiff > 0 && <sup>+{flight.dayDiff}</sup>}
								{flight.dayDiff < 0 && <sup>{flight.dayDiff}</sup>}
							</b>
							<span className={s["infoBlock-locate"]}>
								{flight.arrAirportCity} ({flight.arrAirport})
							</span>
							<div className={s["infoBlock__adv"]}>
								<span className={s["infoBlock__adv-text"]}>
									{/* {flight.cabinName} Class · Airbus A319 */}
									<b>{flight.cabinName}</b>
								</span>
								<span className={s["infoBlock__adv-text"]}>
									{flight.airlineName} · {flight.airline} {flight.flightNumber}
								</span>
								<span className={s["infoBlock__adv-text"]}>
									Operated by {flight.operatingCarrier}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}

			{!mobile && (
				<div className={s.info}>
					<div className={s["info-avatar"]}>
						<img
							className={s["img"]}
							src={
								"https://offer.otti.omeron.co/static/square/" +
								flight.airline +
								".png"
							}
							alt="carrier"
							
						/>
					</div>
					<div className={s["info__info"]}>
						<div className={s.infoBlock}>
							<b
								className={`${s["infoBlock-time"]} ${s["infoBlock-time--line"]}`}>
								{flight.depTime}&nbsp; · &nbsp;
								<span className={s["infoBlock-locate"]}>
									{flight.depAirportCity} ({flight.depAirport})
								</span>
							</b>
							<div className={s["infoBlock__adv"]}>
								<span className={s["infoBlock__adv-text"]}>
									Travel time: {GetHoursMinutes(flight.travelTime).hours} hr{" "}
									{GetHoursMinutes(flight.travelTime).minutes} min
									{flight.redEye && (
										<>
											&nbsp; · &nbsp;
											<div className={s["warrning"]}>
											 <WarrningIcon />{" "}Overnight 
											</div>
										</>
									)}
								</span>
							</div>
						</div>

						<div className={s.infoBlock}>
							<b className={s["infoBlock-time"]}>
								{flight.arrTime}{" "}
								{flight.dayDiff > 0 && <sup>+{flight.dayDiff}</sup>}
								{flight.dayDiff < 0 && <sup>{flight.dayDiff}</sup>}
								&nbsp;{flight.dayDiff ? "" : " " }· &nbsp;
								<span className={s["infoBlock-locate"]}>
									{flight.arrAirportCity} ({flight.arrAirport})
								</span>
							</b>

							<div className={s["infoBlock__adv"]}>
								<span className={s["infoBlock__adv-text"]}>
									<b>{flight.cabinName}</b>
								</span>
								<span className={s["infoBlock__adv-text"]}>
									{flight.airlineName}&nbsp; · &nbsp;{flight.airline}{" "}
									{flight.flightNumber}&nbsp; · &nbsp;Operated by{" "}
									{flight.operatingCarrier}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
