const SvgRefund = (props) => (
  <svg
    width="4rem"
    height="4rem"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#refund_svg__a)">
      <path
        d="M44.87 7.826H3.13A3.134 3.134 0 0 0 0 10.956v26.088a3.134 3.134 0 0 0 3.13 3.13h29.74a.522.522 0 1 0 0-1.044H3.13a2.09 2.09 0 0 1-2.087-2.087V20.87h45.913v4.174a.522.522 0 0 0 1.044 0V10.956a3.134 3.134 0 0 0-3.13-3.13ZM3.13 8.87h41.74a2.09 2.09 0 0 1 2.087 2.087v2.086H1.042v-2.086A2.09 2.09 0 0 1 3.13 8.87ZM1.043 19.826v-5.739h45.913v5.74H1.044Zm2.61 12a.522.522 0 0 0-.523.522v2.087a.522.522 0 0 0 .522.521h7.826a.522.522 0 0 0 .522-.521v-2.087a.522.522 0 0 0-.522-.522H3.652Zm7.303 2.087H4.175V32.87h6.782v1.043Zm3.653-2.087a.522.522 0 0 0-.522.522v2.087a.521.521 0 0 0 .522.521h10.956a.521.521 0 0 0 .522-.521v-2.087a.522.522 0 0 0-.522-.522H14.61Zm10.435 2.087H15.13V32.87h9.914v1.043Zm15.13-9.391c-4.315 0-7.826 3.51-7.826 7.826 0 4.315 3.51 7.826 7.826 7.826 4.315 0 7.826-3.51 7.826-7.826s-3.51-7.826-7.826-7.826Zm0 14.608a6.782 6.782 0 1 1 6.782-6.782 6.79 6.79 0 0 1-6.782 6.782Zm5.217-6.782A3.656 3.656 0 0 1 41.74 36h-5.217a.522.522 0 1 1 0-1.044h5.217a2.609 2.609 0 1 0 0-5.217h-3.436l.675.675a.522.522 0 1 1-.738.738l-1.565-1.566a.521.521 0 0 1 0-.737l1.565-1.566a.521.521 0 0 1 .738.738l-.675.675h3.436a3.656 3.656 0 0 1 3.652 3.652Z"
        fill="#A66E49"
        stroke="#A66E49"
        strokeWidth={0.4}
      />
    </g>
    <defs>
      <clipPath id="refund_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgRefund;
