const SvgSupport = (props) => (
  <svg
    width="4rem"
    height="4rem"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.401 27.944a.616.616 0 0 1-.624.624h-4.02a.623.623 0 0 1-.488-.204.627.627 0 0 1 .034-.886l3.361-3.043c.569-.51.63-1.407.162-2.005-.481-.616-1.399-.719-2.025-.265-.25.182-.459.51-.78.565a.633.633 0 0 1-.695-.417c-.158-.454.23-.725.522-.989a2.682 2.682 0 0 1 1.828-.704c.738 0 1.488.307 2.021.897.477.511.704 1.181.704 1.828 0 .738-.306 1.488-.897 2.021l-2.146 1.942h2.419c.352 0 .624.284.624.636ZM29.793 26.457a.625.625 0 0 1-.636.624h-.113v.863a.616.616 0 0 1-.625.625.616.616 0 0 1-.624-.625v-.863h-3.554a.627.627 0 0 1-.51-1.01c.144-.18 4.153-5.177 4.2-5.223.04-.038.09-.089.137-.12.41-.264.976.013.976.517v4.587h.114c.351 0 .635.284.635.625Zm-1.998-3.43-2.26 2.805h2.26v-2.804Z"
      fill="#A66E49"
    />
    <path
      d="M44.253 16.476C42.54 6.849 33.643 0 24 0S5.46 6.85 3.747 16.476C1.627 16.806 0 18.632 0 20.828v2.328c0 .907 1.406.907 1.406 0v-2.328c0-1.654 1.36-3 3.033-3h1.467v14.59H4.44c-1.672 0-3.033-1.346-3.033-3v-2.7c0-.906-1.406-.906-1.406 0v2.7c0 2.43 1.991 4.407 4.439 4.407H5.98c.652 2.434 4.385 1.955 4.385-.57V16.99c0-2.52-3.731-3.008-4.385-.57h-.793C6.915 7.602 15.14 1.407 24 1.407c8.86 0 17.085 6.196 18.812 15.016h-.793c-.652-2.435-4.385-1.956-4.385.57v16.263c0 2.52 3.732 3.008 4.385.57h.783c-1.261 6.681-7.37 11.037-14.006 11.247a2.232 2.232 0 0 0-2.112-1.505h-5.368c-1.208 0-2.23 1.004-2.23 2.216 0 1.213 1.022 2.217 2.23 2.217h5.368c.985 0 1.823-.639 2.117-1.521 7.436-.22 14.176-5.215 15.439-12.706 2.126-.325 3.76-2.154 3.76-4.355v-8.59c0-2.196-1.627-4.021-3.747-4.352Zm-36.94.515c0-1.046 1.647-1.046 1.647 0v16.264c0 1.046-1.648 1.047-1.648 0V16.99Zm19.371 29.603h-5.368c-1.06 0-1.06-1.62 0-1.62h5.368c1.06 0 1.06 1.62 0 1.62Zm14.003-13.34c0 1.047-1.647 1.048-1.647 0V16.993c0-1.047 1.648-1.047 1.648 0v16.263Zm5.907-3.836c0 1.655-1.36 3-3.033 3h-1.467v-14.59h1.467c1.672 0 3.033 1.346 3.033 3v8.59Z"
      fill="#A66E49"
    />
    <path
      d="m27.635 35.513 4.228 1.863a.71.71 0 0 0 .987-.643v-4.705a11.439 11.439 0 0 0 2.76-7.454c0-2.41-.743-4.72-2.15-6.677-.528-.736-1.67.085-1.141.821a9.976 9.976 0 0 1 1.886 5.856c0 2.481-.917 4.867-2.581 6.719a.703.703 0 0 0-.18.47v3.891l-3.493-1.538a.703.703 0 0 0-.518-.02c-1.1.39-2.256.587-3.433.587-5.627 0-10.205-4.535-10.205-10.109S18.373 14.464 24 14.464c2.098 0 4.113.626 5.828 1.81.746.515 1.545-.642.799-1.157A11.608 11.608 0 0 0 24 13.058c-6.402 0-11.61 5.166-11.61 11.516S17.597 36.09 24 36.09c1.243 0 2.464-.194 3.635-.577Z"
      fill="#A66E49"
    />
  </svg>
);

export default SvgSupport;
