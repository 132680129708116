import PropTypes from 'prop-types'
import s from './index.module.sass'

export default function PopoverButton({type, children, ...props}) {
  let classes = [s.btn]
  if (type === 'dark') classes.push(s['btn--dark'])
  else if (type === 'outline') classes.push(s['btn--outline'])
  else classes.push(s['btn--default'])

  return <button className={classes.join(' ')} {...props}>{children}</button>
}

PopoverButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.element
}