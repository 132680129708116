import s from './index.module.sass'

// components
import Toggle from '../UI/Toggle'
import DropDown from '../UI/DropDown'
import MoveLabelInput from '../UI/Input/moveLabel'

//hooks
import { EmailValidate, PhoneValidate } from '../../hooks/useFormValidate'

// jsons
import phoneJson from '../../jsons/phoneJson.json'

export default function SomeDetails({
  status,
  onChange,
  submitted,
  disabled = false,

  countryCode,
  someCountryCode,
  setSomeCountryCode,

  phoneInput,
  somePhoneInput,
  setSomePhoneInput,

  emailInput,
  someEmailInput,
  setSomeEmailInput

}) {
  return (
    <>
      <div className={s['details__check']}>
        <Toggle
          active={status}
          change={onChange}
        />
        <span className={s['details__check-text']}>Same details from &quot;Contact Person&quot;</span>
      </div>
      <div className={s['details__form']}>
        <div className={s['grid']}>
          <DropDown
            label="Country code"
            move={false}
            smallText={true}
            placeholder="Click to select"
            value={status ? countryCode.text : someCountryCode.text}
            setValue={(e) => setSomeCountryCode(e)}
            items={phoneJson.items}
            style={{ fontSize: 13 }}
            disabled={disabled || status ? true : false}
            hasError={submitted && !(status ? countryCode.text : someCountryCode.text)}
          />
          <MoveLabelInput
            label="Billing Phone Number"
            move={true}
            value={status ? phoneInput : somePhoneInput}
            setValue={setSomePhoneInput}
            disabled={disabled || status ? true : false}
            inputmode="tel" 
            type="tel"
            hasError={submitted && (!PhoneValidate(status ? phoneInput : somePhoneInput))}
          />
        </div>
        <MoveLabelInput
          label="Card Holder's Email"
          move={true}
          value={status ? emailInput : someEmailInput}
          disabled={disabled || status ? true : false}
          setValue={setSomeEmailInput}
          hasError={submitted && (!EmailValidate(status ? emailInput : someEmailInput))}
        />
      </div>
    </>
  )
}