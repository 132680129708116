import s from "./index.module.sass";

// components
import Radio from "../UI/Radio";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setProtect } from "../../store/passenger";

//hooks
import { FormatPrice, GetTicketProtectionPrice } from "../../hooks/useGetPrice";

export default function PassengersProtection({
	info,
	locked = true,
	hideNotProtect = false,
	trip,
	tpGrandTotal,
	answeredTPInfo = null,
}) {
	return (
		<div className={s.block}>
			<h1 className={s["block-title"]}>
				{locked ? (
					<>Passengers with Ticket Protection</>
				) : (
					<>You can Protect Passengers Separately!</>
				)}
			</h1>

			{answeredTPInfo && (
				<>
					{answeredTPInfo.map((item, index) => {
						return <AnsweredItem data={item} key={index} />;
					})}
				</>
			)}

			{!answeredTPInfo && (
				<>
					{info?.map((item, index) => {
						return (
							<Item
								data={item}
								id={index}
								locked={locked}
								hideNotProtect={hideNotProtect}
								key={index}
								tpPrice={GetTicketProtectionPrice(item, trip)}
							/>
						);
					})}
				</>
			)}

			<div className={s["block__footer"]}>
				<span className={s["block__footer-text"]}>Total:</span>
				<span className={s["block__footer-value"]}>
					{FormatPrice(tpGrandTotal)}
				</span>
			</div>
		</div>
	);
}

const Item = ({ data, id, locked, hideNotProtect, tpPrice }) => {
	const dispatch = useDispatch();

	if (
		!data.firstName ||
		!data.lastName ||
		data.firstName.length < 2 ||
		data.lastName.length < 2
	)
		return <></>;
	if (hideNotProtect && !data?.protect) return <></>;

	return (
		<div
			className={s["block__line"]}
			onClick={() =>
				locked ? null : dispatch(setProtect({ id, value: !data?.protect }))
			}>
			<Radio checked={data.protect} />
			<span className={s["block__line-title"]}>
				{data?.firstName} {data?.middleName} {data?.lastName}
			</span>
			<span className={s["block__line-price"]}>{FormatPrice(tpPrice)}</span>
		</div>
	);
};

const AnsweredItem = ({ data }) => {
	const TP_INFO = data.split(":");

	return (
		<div className={s["block__line"]}>
			<Radio checked={true} />
			<span className={s["block__line-title"]}>{TP_INFO[0]}</span>
			<span className={s["block__line-price"]}>{FormatPrice(TP_INFO[1])}</span>
		</div>
	);
};
