import { useEffect, setState } from 'react'
import Slider from 'rc-slider'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { setTips } from '../../../store/tips'

export default function PriceSelector({ value, disabled = false}) {

  const dispatch = useDispatch()

  const marks = {
    0: <span>NO<br />$0</span>,
    20: <span>Good<br />$100</span>,
    40: <span>Great<br />$150</span>,
    60: <span>Excellent<br />$200</span>,
    80: <span>Perfect<br />$300</span>,
  };

  const handleChange = (e) => {
  
    if (e === 0) dispatch(setTips(0))
    else if (e === 20) dispatch(setTips(100))
    else if (e === 40) dispatch(setTips(150))
    else if (e === 60) dispatch(setTips(200))
    else if (e === 80) dispatch(setTips(300))
  }

  useEffect(() => {
    let track = document.querySelectorAll(".rc-slider-track")
    let rail = document.querySelectorAll(".rc-slider-rail")
    let staps = document.querySelectorAll(".rc-slider-step")

    setTimeout(() => {

      for (let i = 0; i < staps.length; i++) {

        let staps_childrens = staps[i].children
        let last_active_staps
        let last_staps

        for (let j = 0; j < staps_childrens.length; j++) {
          if (staps_childrens[j].classList.contains("rc-slider-dot-active")) last_active_staps = staps_childrens[j]
          last_staps = staps_childrens[j]
        }

        for (let f = 0; f < track.length; f++) {
          track[f].style.width = `calc(${last_active_staps.style.left} - 9%)`
          track[f].style.left = '9%'
        }

        for (let f = 0; f < rail.length; f++) {
          rail[f].style.width = `calc(${last_staps.style.left} - 9%)`
          rail[f].style.left = '9%'
        }

      }
    }, 0)

  }, [value])

  return (
    <div className="rc-div">
      <Slider
        min={-10}
        marks={marks}
        step={null}
        // defaultValue={value - 30}
        value={value}
        onChange={(e) => {if(!disabled) handleChange(e)} }
      />
    </div>
  )
}